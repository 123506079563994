//problème sur les version npm donc on utilise la version local !
//import sweetalert from "sweetalert";
import "./style.scss";

// Export necessaire par défaut

export default ($view) => {

    $view.on("click", (event) => {
        //stop la propagation de l'évènement click
        event.preventDefault();
        initSwal(
            $view,
            function (isConfirm) {
                if (isConfirm) {
                    //distribuer l'événement.
                    $view.trigger('swalConfirm');
                }
            }
        );
    });
} 

export function initSwal($view, $onConfirm) {
    swal(mergeProps($view),$onConfirm);
}

export function mergeProps($view) {
    let defaultProps = getDefaultProps($view);
    return Object.assign(defaultProps, $view.data());
}

export function getDefaultProps($view) {
    return  { 
        title: "",
        type: "info",
        text:$view.find(".swal-content").html(),
        html: true,
        showCancelButton: true,
        confirmButtonColor: "#333333",
        cancelButtonColor: "#333333",
        cancelButtonText: "Annuler",
        confirmButtonText: "Valider",
        closeOnConfirm: true,
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
    };
}