var modal = null;
var propertyName;
var uploadPath;
var fileName;
//= "#ImagePrincipale";

export function UploadPhoto(id) {
    GetModal("Modifier la photo de profil", "720px", "1000px");
    $.ajax({
        url: urlGetPartialUpload,
        type: 'POST',
        data: { id: "" + id + "" },
        success: function (data) {
            modal.data("kendoWindow").content(data);
            return new crop($('#body'));
        }
    });
}



export function SupprimerPhoto(id, nom) {
    swal({
        title: "Suppression photo",
        text: "�tes-vous s�r de vouloir supprimer la photo de <b>" + nom + "</b> ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#333333",
        cancelButtonColor: "#333333",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
        closeOnConfirm: true,
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        html: true
    },
        function (isConfirm) {
            if (isConfirm) {
                $.ajax({
                    url: urlSuppressionPhoto,
                    type: 'POST',
                    data: { id: id },
                    success: function (data) {
                        $('#Grid').data('kendoGrid').dataSource.read();
                        $('#Grid').data('kendoGrid').refresh();
                        toastr.success("Photo supprim�e");
                        swal.close();
                    }
                });

            }
        });
}

export function closeUploadPhoto() {
    modal.data("kendoWindow").content("");
    modal.data("kendoWindow").close();
}


function end() {
    crop.End();
}

var modal;

function GetModal(title, height, width) {

    if (height == 'undefined' || height == "")
        height = "90%";
    if (width == 'undefined' || width == "")
        width = "1000px";
    if (modal == null || modal.data("kendoWindow") == undefined) {
        var window = $("#window");
        window.kendoWindow({
            title: title,
            modal: true,
            width: width,
            height: height,
            actions: [
                "Maximize",
                "Close"
            ]
        });
        modal = window;
    }
    modal.data("kendoWindow").title(title);
    modal.data("kendoWindow").center().open();
}


function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
}

$.fn.safeUrl = function (args) {
    var that = this;
    if ($(that).attr('data-safeurl') && $(that).attr('data-safeurl') === 'found') {
        return that;
    } else {
        $.ajax({
            url: args.wanted,
            type: 'HEAD',
            error:
                function () {
                    $(that).attr('src', args.rm);
                },
            success:
                function () {
                    $(that).attr('src', args.wanted);
                    $(that).attr('data-safeurl', 'found');
                }
        });
    }


    return that;
};


(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as anonymous module.
        define(['jquery'], factory);
    } else if (typeof exports === 'object') {
        // Node / CommonJS
        factory(require('jquery'));
    } else {
        // Browser globals.
        factory(jQuery);
    }
})(function ($) {

    'use strict';

    var console = window.console || { log: function () { } };

    function CropAvatar($element) {
        this.$container = $element;

        this.$i = 0;

        this.$avatarView = this.$container.find('.avatar-view');
        this.$avatar = this.$avatarView.find('img');
        this.$avatarModal = this.$container.find('#window');
        this.$loading = this.$container.find('.loading');

        this.$avatarForm = this.$avatarModal.find('.avatar-form');
        this.$avatarUpload = this.$avatarForm.find('.avatar-upload');
        this.$avatarSrc = this.$avatarForm.find('.avatar-src');
        this.$avatarData = this.$avatarForm.find('.avatar-data');
        this.$avatarInput = this.$avatarForm.find('.avatar-input');
        this.$avatarSave = this.$avatarForm.find('.avatar-save');
        this.$avatarBtns = this.$avatarForm.find('.avatar-btns');

        this.$avatarWrapper = this.$avatarModal.find('.avatar-wrapper');
        this.$avatarPreview = this.$avatarModal.find('.avatar-preview');
        this.init();
        this.initPreview();
    }

    function End() {
        this.stopCropper();
    }

    window.crop = CropAvatar;

    CropAvatar.prototype = {
        constructor: CropAvatar,

        support: {
            fileList: !!$('<input type="file">').prop('files'),
            blobURLs: !!window.URL && URL.createObjectURL,
            formData: !!window.FormData
        },

        init: function () {
            this.support.datauri = this.support.fileList && this.support.blobURLs;

            if (!this.support.formData) {
                this.initIframe();
            }

            this.initTooltip();
            this.initModal();
            this.addListener();
        },

        addListener: function () {
            this.$avatarView.on('click', $.proxy(this.click, this));
            this.$avatarInput.on('change', $.proxy(this.change, this));
            var upload = $("#avatarInput").data("kendoUpload");
            upload.bind("complete", $.proxy(this.change, this));
        },

        initTooltip: function () {
            this.$avatarView.tooltip({
                placement: 'bottom'
            });
        },

        initModal: function () {
            this.$avatarModal.modal({
                show: false
            });
        },

        initPreview: function () {
            var url = this.$avatar.attr('src');
            var _this = this;
            if ($("#IsFileExist").val() == "True") {
                _this.$avatarPreview.html('<img src="/Upload/Dossiers/' + $("#UtilisateurID").val() + '/Configuration/avatar.png?id=' + guid() + '">');
            } else {
                _this.$avatarPreview.html('<img src="/Content/images/person.png">');
            }
        },

        initIframe: function () {
            var target = 'upload-iframe-' + (new Date()).getTime();
            var $iframe = $('<iframe>').attr({
                name: target,
                src: ''
            });
            var _this = this;

            // Ready ifrmae
            $iframe.one('load', function () {

                // respond response
                $iframe.on('load', function () {
                    var data;

                    try {
                        data = $(this).contents().find('body').text();
                    } catch (e) {
                        console.log(e.message);
                    }

                    if (data) {
                        try {
                            data = $.parseJSON(data);
                        } catch (e) {
                            console.log(e.message);
                        }

                        _this.submitDone(data);
                    } else {
                        _this.submitFail('Image upload failed!');
                    }

                    _this.submitEnd();

                });
            });

            this.$iframe = $iframe;
            this.$avatarForm.attr('target', target).after($iframe.hide());
        },

        click: function () {
            this.$avatarModal.modal('show');
            this.initPreview();
        },

        change: function (e) {

            this.initPreview();


            var files,
                file;

            if (this.support.datauri) {


                files = $.upload.files;

                console.log(files);

                if (files.length > 0) {

                    file = files[0].rawFile;
                    if (this.isImageFile(file)) {
                        console.log("true");
                        this.url = URL.createObjectURL(file);
                        this.startCropper($("#formAddCreneaux").attr("isnotsquare"));
                    }
                }
            } else {

                file = this.$avatarInput.val();

                if (this.isImageFile(file)) {
                    this.syncUpload();
                }
            }
        },

        submit: function () {
            if (!this.$avatarSrc.val() && !this.$avatarInput.val()) {
                return false;
            }

            if (this.support.formData) {
                this.ajaxUpload();
                return false;
            }
        },

        rotate: function (e) {
            var data;

            if (this.active) {
                data = $(e.target).data();

                if (data.method) {
                    this.$img.cropper(data.method, data.option);
                }
            }
        },

        isImageFile: function (file) {
            if (file.type) {
                return /^image\/\w+$/.test(file.type);
            } else {
                return /\.(jpg|jpeg|png|gif)$/.test(file);
            }
        },

        startCropper: function (isNotSqlare) {
            var _this = this;
            var ratio = 1;
            if (isNotSqlare == "true")
                ratio = 0;
            if (this.active) {
                this.$img.cropper('replace', this.url);
            } else {
                this.$img = $('<img id="cropper" src="' + this.url + '">');
                this.$avatarWrapper.empty().html(this.$img);
                this.$img.cropper({
                    aspectRatio: ratio,
                    preview: this.$avatarPreview.selector,
                    strict: false,
                    crop: function (e) {
                        var json;

                        json = [
                            '{"x":' + e.x,
                            '"y":' + e.y,
                            '"height":' + e.height,
                            '"width":' + e.width,
                            '"rotate":' + e.rotate + '}'
                        ].join();

                        _this.$avatarData.val(json);
                    }
                });

                this.active = true;
            }

            this.$avatarModal.one('hidden.bs.modal', function () {
                _this.$avatarPreview.empty();
                _this.stopCropper();
            });
        },

        stopCropper: function () {
            if (this.active) {
                this.$img.cropper('destroy');
                this.$img.remove();
                this.active = false;
            }
        },

        ajaxUpload: function () {
            var url = this.$avatarForm.attr('action');
            var data = new FormData(this.$avatarForm[0]);
            var _this = this;

            $.ajax(url, {
                type: 'post',
                data: data,
                dataType: 'json',
                processData: false,
                contentType: false,

                beforeSend: function () {
                    _this.submitStart();
                },

                success: function (data) {
                    _this.submitDone(data);
                },

                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    _this.submitFail(textStatus || errorThrown);
                },

                complete: function () {
                    _this.submitEnd();
                }
            });
        },

        syncUpload: function () {
            this.$avatarSave.click();
        },

        submitStart: function () {
            this.$loading.fadeIn();
        },

        submitDone: function (data) {
            console.log(data);

            if ($.isPlainObject(data) && data.state === 200) {
                if (data.result) {
                    this.url = data.result;

                    if (this.support.datauri || this.uploaded) {
                        this.uploaded = false;
                        this.cropDone();
                    } else {
                        this.uploaded = true;
                        this.$avatarSrc.val(this.url);
                        alert("isnotsquare");
                        alert($("#formAddCreneaux").attr("isnotsquare"));
                        this.$avatarForm.find('.avatar-save');
                        this.startCropper($("#formAddCreneaux").attr("isnotsquare"));
                    }

                    this.$avatarInput.val('');
                } else if (data.message) {
                    this.alert(data.message);
                }
            } else {
                this.alert('Failed to response');
            }
        },

        submitFail: function (msg) {
            this.alert(msg);
        },

        submitEnd: function () {
            this.$loading.fadeOut();
        },

        cropDone: function () {
            this.$avatarForm.get(0).reset();
            this.$avatar.attr('src', this.url);
            this.stopCropper();
            this.$avatarModal.modal('hide');
        },

        alert: function (msg) {
            var $alert = [
                  '<div class="alert alert-danger avatar-alert alert-dismissable">',
                    '<button type="button" class="close" data-dismiss="alert">&times;</button>',
                    msg,
                  '</div>'
            ].join('');

            this.$avatarUpload.after($alert);
        }
    };
});
