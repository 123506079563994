$.fn.Projet = {
    startLitige: function (e, id) {
        e.preventDefault();
        $.ajax({
            url: Resource.litigeUrlGetModal,
            type: 'POST',
            data: { id: id },
            success: function (data) {
                $("#mModal .modal-dialog").html(data);
                $("#mModal").modal("show");
            }
        });
    },
    startLitigeOnSuccess: function () {
        $("#mModal").modal("hide");
        $("#mModal .modal-dialog").html("");
        toastr.success(Resource.litigeSuccess);
        setTimeout(function () { window.location.reload(true); }, 2000);
    },
    cancelLitige: function () {
        $("#mModal").modal("hide");
        $("#mModal .modal-dialog").html("");
        return false;
    },
    endLitige: function (e, id) {
        e.preventDefault();
        swal({
            title: Resource.endLitigeTitle,
            text: Resource.endLitigeMessage,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#333333',
            cancelButtonColor: '#D0D0D0',
            cancelButtonText: Resource.No,
            confirmButtonText: Resource.Yes,
            closeOnConfirm: false,
            allowOutsideClick: false
        },
            function (isConfirm) {
                if (isConfirm) {
                    $.ajax({
                        url: Resource.endLitigeUrl,
                        type: "POST",
                        contentType: "application/json; charset=utf-8",
                        dataType: "json",
                        data: JSON.stringify({
                            id: id
                        })
                    }).done(function (data) {
                        if (data.success === 1) {
                            toastr.success(Resource.endLitigeSuccess);
                            setTimeout(function () { window.location.reload(true); }, 2000);
                        }
                        else {
                            toastr.error(Resource.endLitigeFailed);
                        }
                    });
                }
                swal.close();
            }
        );
    }
};