import { Toast } from "bootstrap";

export default class DeleteClientDocument {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        this.view.on("click", function (event) {
            event.preventDefault();
            var $view = $(event.target);
            var defaultProps = $.fn.Swal.getDefaultProps($view);
            var props = Object.assign(defaultProps, $view.data());
            swal(props,
                function (confirmed) {
                    if (confirmed) {
                        $.ajax({
                            url: $view.data("url") + '/' + $view.data("fichierid"),
                            type: "POST",
                            contentType: "application/json; charset=utf-8",
                        })
                            .done(function (datas) {
                                var message = $view.data("messagesuccess");
                                if (datas.message) {
                                    message += "<br/>" + datas.message;
                                }
                                toastr.success(message);

                                var grid = $('#' + $view.data("grid")).data('kendoGrid');
                                grid.dataSource.read();
                                grid.refresh();
                                swal.close();
                            })
                            .fail(function (datas) {
                                var message = $view.data("messageerror");
                                if (datas.responseJSON) {
                                    message = datas.responseJSON.message;

                                    // L'erreur vient de l'envoi du mail, la modification en base a été faite
                                    var grid = $('#' + $view.data("grid")).data('kendoGrid');
                                    grid.dataSource.read();
                                    grid.refresh();
                                }
                                toastr.error(message);
                                swal.close();
                            });
                    }
                    else {
                        swal.close();
                    }
                }
            );
        });
        $.fn.Swal = {
            getDefaultProps: function ($view) {
                return {
                    title: "",
                    type: "info",
                    text: $view.find(".swal-content").html(),
                    html: true,
                    showCancelButton: true,
                    confirmButtonColor: "#333333",
                    cancelButtonColor: "#D0D0D0",
                    cancelButtonText: "Annuler",
                    confirmButtonText: "Valider",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false
                };
            }
        };
    }
}