(function ($) {
    $.fn.OrderableGrid = function (options) {
        var defaults = {
            url: "Order"
        };

        var settings = $.extend(defaults, options);

        return $(this).each(function (e) {
            $(this).addClass("grid-orderable");
            $(this).attr("data-orderable-change-url", settings.url);
        });
    }
}(jQuery));

var noHint = $.noop;
window.noHint = noHint;
var crmOrderable = {
    placeholder: function (element) {
        return element.clone().addClass("k-state-hover").css("opacity", 0.65);
    },
    onChange: function (e) {
        try {
            var divGrid = $(e.item[0]).closest(".grid-orderable");
            var grid = $(divGrid).data("kendoGrid"),
                skip = grid.dataSource.skip();
            if (typeof (skip) == "undefined") skip = 0;

            var oldIndex = e.oldIndex + skip,
                newIndex = e.newIndex + skip,
                data = grid.dataSource.data(),
                dataItem = grid.dataSource.getByUid(e.item.data("uid"));
            grid.dataSource.remove(dataItem);
            grid.dataSource.insert(newIndex, dataItem);

            var datas = JSON.stringify({ viewModel: dataItem, oldOrder: oldIndex + 1, newOrder: newIndex + 1 }, null, 2);

            jQuery.ajax({
                url: $(divGrid).data("orderable-change-url"),
                type: "POST",
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: datas,
                success: function (response) {
                    grid.dataSource.read(); //refresh the Grid
                },
                error: function () {
                    alert('Error');
                },
                complete: function () {
                    grid.dataSource.cancelChanges();
                }
            });
        }
        catch (err) {
            //Onlimite les erreurs qui peuvent subvenir
        }
    }
}

window.crmOrderable = crmOrderable;

//Pour utiliser le reOrder Via drag  and drop sur les subgrid (Hierarchical template)
var crmOrderableSubGrid = {
    placeholder: function (element) {
        return element.clone().addClass("k-state-hover").css("opacity", 0.65);
    },
    onChange: function (e) {
        try {
            //Ici on cherche dans une grid décoré de le classe subgrid uniquement
            var divGrid = $(e.item[0]).closest(".subgrid-orderable");
            var grid = $(divGrid).data("kendoGrid");
            var oldIndex = e.oldIndex;
            var newIndex = e.newIndex;
            var data = grid.dataSource.data();
            var dataItem = grid.dataSource.getByUid(e.item.data("uid"));

            grid.dataSource.remove(dataItem);
            grid.dataSource.insert(newIndex, dataItem);

            var datas = JSON.stringify({ viewModel: dataItem, oldOrder: oldIndex + 1, newOrder: newIndex + 1 }, null, 2);

            jQuery.ajax({
                url: $(divGrid).data("orderable-change-url"),
                type: "POST",
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: datas,
                success: function (response) {
                    grid.dataSource.read(); //refresh the Grid
                },
                error: function () {
                    alert('Error');
                },
                complete: function () {
                    grid.dataSource.cancelChanges();
                }
            });
        }
        catch (err) {
        }
    }
}
window.crmOrderableSubGrid = crmOrderableSubGrid;


//Regroupe les fonctions de gestions de la grid Inline
var inlineEditableGridCommand = {
    changeButton: function (e) {
        //On récupère le contexte toussa afin  de retrouver la Grid
        var current = e.container[0];
        var grid = $(current).closest(".k-grid").data("kendoGrid");
        var dataItem = grid.dataItem(current);

        //On récupère la cellule correspondante aubouton d'editions et les modifie
        var cell = grid.tbody.find("tr[data-uid='" + dataItem.uid + "']").find(".editTemplate");
        cell.html("<a data-toggle=\"tooltip\" data-placement=\"bottom\" title=\"Enregistrer\" class=\"tooltiplink link k-button-icontext k-grid-update btn btn-default\"><i class=\"fa fa-check\"></i></a><a data-toggle=\"tooltip\" data-placement=\"bottom\" title=\"Annuler\" style=\"margin-left:4px\" class=\"tooltiplink link k-button-icontext k-grid-cancel btn btn-red\"><i class=\"fa fa-close\"></i></a>");

        return dataItem;
    }
};
window.inlineEditableGridCommand = inlineEditableGridCommand;


var crmGridCommand = {
    createEntity: function (e, url) {
        e.preventDefault();
        document.location = url;
    },
    editEntity: function (e, baseUrl) {
        e.preventDefault();
        var row = $(e.target).closest("tr");
        var grid = $(row).closest(".k-grid").data("kendoGrid");
        var dataItem = grid.dataItem(row);
        document.location = baseUrl + "/" + dataItem.id;
    },
    detailEntity: function (e, baseUrl) {
        e.preventDefault();
        var row = $(e.target).closest("tr");
        var grid = $(row).closest(".k-grid").data("kendoGrid");
        var dataItem = grid.dataItem(row);
        document.location = baseUrl + "/" + dataItem.id;
    },
    destroyConfirmEntity: function (e) {
        if (typeof(nbCurrentRequests) != "undefined" && nbCurrentRequests > 0) {
            return;
        }
        crmAlert.confirm("Suppression ?", "Etes-vous sûr de vouloir supprimer cet élément ?", function () {
            crmGridCommand.destroyEntity(e);
        });
    },
    destroyEntity: function (e) {
        e.preventDefault();
        try 
        {
            var row = $(e.target).closest("tr");
            var grid = $(row).closest(".k-grid").data("kendoGrid");
            var dataItem = grid.dataItem(row);
            grid.dataSource.remove(dataItem);  //prepare a "destroy" request 
            grid.dataSource.sync(dataItem);
        }
        catch (e)
        {
            swal("Erreur", "Une erreur est survenue", "error");
        }
    },
    entityActivation: function (e, url, activationState) {
        e.preventDefault();
        var row = $(e.target).closest("tr");
        var grid = $(row).closest(".k-grid").data("kendoGrid");
        var dataItem = grid.dataItem(row);

        var data = {
            id: dataItem.id,
            activationState: activationState
        }
        $.ajax({
            url: url,
            method: "POST",
            data: data
        }).done(function () {
            var action = (activationState) ? "réactivé" : "désactivé";
            crmAlert.success("L'élément a bien été " + action, 2000);
            $(row).find('.js-action-activate').toggleClass('hidden');
            var checkState = $(row).find('.js-state-Actif');
            checkState.prop('checked', !checkState.is(':checked'));
        })
        .fail(function () {
            crmAlert.error("Une erreur est survenue.");
        })
    }
};

window.crmGridCommand = crmGridCommand;

//Pour lancer la création de Row
function createRow(Id) {
    grid = $("#" + Id).data("kendoGrid");
    grid.addRow();
}

//Quand la grille pass en mode edition
function onGrid_Edit(e) {
    //On appel la méthode de changement de button
    var dataItem = inlineEditableGridCommand.changeButton(e);
}

function grid_error(e) {
    if (e.errors != null) {
        var message = "";
        // Create a message containing all errors.
        $.each(e.errors, function (key, value) {
            if ('errors' in value) {
                $.each(value.errors, function () {
                    message += this + "\n";
                });
            }
        });
        this.cancelChanges();
        crmAlert.error(message);
    }
}