import "./style.scss";

// Export necessaire par défaut
// Fonction lambda


//hack pour pouvoir déclarer une fonction global en TS
//declare var $: any;
//declare var WebpackApp: any;

export default ($view) => {
    
    $view.on("click", (event) => {
        //stop la propagation de l'évènement click
        event.preventDefault();

        $.ajax({
            url: $view.data('url'),
            method: $view.data('method') ? $view.data('method') : "POST",
            data: $view.data('parameters') ? $view.data('parameters') : {},
            success: function (data) {
                let $modal = $("#appModal");
                let $btnConfirm = $modal.find('.modal-confirm');
                $modal.find(".modal-dialog").addClass(data.stylelayout);
                $modal.find(".modal-title").html(data.title);
                $modal.find(".modal-body").html(data.content);
                if (data.cancelLabel == null) {
                    $modal.find(".modal-cancel").hide();
                }
                else {
                    $modal.find(".modal-cancel").show();
                    $modal.find(".modal-cancel").html(data.cancelLabel);
                }

                if (data.confirmLabel == null) {
                    $modal.find(".modal-confirm").hide();
                }
                else {
                    $modal.find(".modal-confirm").show();
                    $modal.find(".modal-confirm").html(data.confirmLabel);
                }
                WebpackApp.Kernel.bindComponents($($modal));
                $($modal).modal("show");
                $('.modal-backdrop').hide();
                //decharge les event click sur le bouton de confirmation
                $btnConfirm.unbind("click");
                //distribuer l'événement.
                $btnConfirm.on("click", function () {
                    console.log($view);
                    console.log($view.data('method'));
                    //console.log($view.data('parameters'));
                    $view.trigger('modalAjaxConfirm'); 
                });
            },
            error: function () {
                toastr.error("Erreur");
                console.log("erreur");
            }
        });


    });
} 