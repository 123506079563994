export default class AddressAutoComplete {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        function addressFormatter(item) {
            return {
                label: item.properties.label,
                postcode: item.properties.postcode,
                citycode: item.properties.citycode,
                city: item.properties.city,
                value: item.properties.name
            };
        };

        function zipCodeFormatter(item) {
            return {
                label: item.properties.postcode + ' - ' + item.properties.city,
                citycode: item.properties.citycode,
                city: item.properties.city,
                value: item.properties.postcode,

            };
        };
        function cityFormatter(item) {
            return {
                label: item.properties.postcode + ' - ' + item.properties.city,
                postcode: item.properties.postcode,
                citycode: item.properties.citycode,
                value: item.properties.city
            };
        };

        function initApiAutocomplete(selector, criteria, formatter, apiCallBack) {
            $(selector).autocomplete({
                source: function (request, response) {
                    $.ajax({
                        url: 'https://api-adresse.data.gouv.fr/search/?' + criteria + '=' + request.term,
                        data: { q: request.term, limit: 15 },
                        dataType: 'json',
                        success: function (data) {

                            //console.log('https://api-adresse.data.gouv.fr/search/?' + criteria + '=' + this.element.val());

                            var results = [];
                            response($.map(data.features, function (item) {
                                if ($.inArray(item.properties.citycode, results) === -1) {
                                    results.push(item.properties.citycode);
                                    return formatter(item);
                                }
                            }));
                        }
                    });
                },
                select: function (event, ui) {
                    apiCallBack(event, ui);
                }
            });
        };

        initApiAutocomplete('.js-api-address', 'label', addressFormatter, function (event, ui) {
            var parent = $(event.target).parents('.js-api-parent');
            $('.js-api-zipcode', parent).val(ui.item.postcode);
            $('.js-api-city-code', parent).val(ui.item.citycode);
            $('.js-api-city', parent).val(ui.item.city);
        });

        initApiAutocomplete('.js-api-zipcode', 'postcode', zipCodeFormatter, function (event, ui) {
            var parent = $(event.target).parents('.js-api-parent');
            $('.js-api-city-code', parent).val(ui.item.citycode);
            $('.js-api-city', parent).val(ui.item.city);
        });

        initApiAutocomplete('.js-api-city', 'city', cityFormatter, function (event, ui) {
            var parent = $(event.target).parents('.js-api-parent');
            $('.js-api-zipcode', parent).val(ui.item.postcode);
            $('.js-api-city-code', parent).val(ui.item.citycode);
        });
    }
}
