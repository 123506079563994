$("#dateDebut").change(function () {
    if (!checkForm($("#dateDebut").val())) {
        toastr.error("Format de date incorrect");
    } else {
        dateIntervalTrue($("#dateDebut").val(), $("#dateFin").val());
    }
});

$("#dateFin").change(function () {
    if (!checkForm($("#dateFin").val())) {
        toastr.error("Format de date incorrect");
    } else {
        dateIntervalTrue($("#dateDebut").val(), $("#dateFin").val());
    }
});

function checkForm(dateInput) {
    re = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
    if (dateInput != '' && !dateInput.match(re)) {
        return false;
    }
    else {
        return true;
    }
}

function dateIntervalTrue(stringDebut, stringFin) {
    var splitDebut = stringDebut.split("/");
    var splitFin = stringFin.split("/");

    var dateDebut = new Date(splitDebut[2], splitDebut[1] - 1, splitDebut[0]);
    var dateFin = new Date(splitFin[2], splitFin[1] - 1, splitFin[0]);

    if (isNaN(dateDebut.getTime())) {
        $("#dateDebut").val(stringFin);
    } else if (dateDebut.getTime() > dateFin.getTime() || isNaN(dateFin.getTime())) {
        $("#dateFin").val(stringDebut);
    }
}