function serialize(data) {
    for (var property in data) {
        if ($.isArray(data[property])) {
            serializeArray(property, data[property], data);
        }
    }
}

function serializeArray(prefix, array, result) {
    for (var i = 0; i < array.length; i++) {
        if ($.isPlainObject(array[i])) {
            for (var property in array[i]) {
                result[prefix + "[" + i + "]." + property] = array[i][property];
            }
        }
        else {
            result[prefix + "[" + i + "]"] = array[i];
        }
    }
}

function errorsManage(grid, e) {
    var gridElement = grid.editable.element;
    var message = "Errors:\n";
    var validationMessageTemplate = kendo.template(
        "<div id='#=field#_validationMessage' " +
        "class='k-widget k-tooltip k-tooltip-validation " +
        "k-invalid-msg field-validation-error' " +
        "style='margin: 0.5em;' data-for='#=field#' " +
        "data-val-msg-for='#=field#' role='alert'>" +
        "<span class='k-icon k-warning'></span>" +
        "#=message#" +
        "<div class='k-callout k-callout-n'></div>" +
        "</div>");
    var message = "";
    $.each(e.errors, function (key, value) {
        if ('errors' in value) {
            $.each(value.errors, function () {
                key = key.split('.')[1];
                gridElement.find("[data-valmsg-for=" + key + "],[data-val-msg-for=" + key + "]").replaceWith(validationMessageTemplate({ field: key, message: this }));
                gridElement.find("input[name=" + key + "]").focus();
            });
        }
    });
    grid.one("dataBinding", function (e) {
        e.preventDefault(); // cancel grid rebind
    });
}

function onErrors(e) {
    if (e.errors) {
        var grid = $('#Grid').data('kendoGrid');
        errorsManage(grid, e);
    }
};

function fitWidget(GridName, Height) {

    if (GridName == undefined) {
        GridName = "Grid";
    }

    if (Height == undefined) {
        Height = 220;
    }

    var widget = $("#" + GridName + "").data("kendoGrid");
    var height = $(window).height() - Height;

    //size widget to take the whole view
    widget.element.height(height);
    widget.resize(true);
}

function onDatabound(e) {

    var gridWrapper = e.sender.wrapper;
    var gridDataTable = e.sender.table;
    var gridDataArea = gridDataTable.closest(".k-grid-content");

    gridWrapper.toggleClass("no-scrollbar", gridDataTable[0].offsetHeight < gridDataArea[0].offsetHeight);

    var showTooltip = function () {
        $('.tooltip').remove(); // This line removes any currently showing tootltips
        $(this).tooltip('show');
    };
    var hideTooltip = function () {
        $(this).tooltip('hide');
    };
    $('.tooltiplink').tooltip({
        trigger: 'manual'
    }).focus(showTooltip).hover(showTooltip, hideTooltip);

    //$('.k-grid-content').height(window.innerHeight - 300);

    var grid = $(e.sender.element);

    // bind row click
    grid.on('click', 'td', function (e) {
        var td = $(this);
        var tr = td.parent();

        if ((td.hasClass('actions') && !grid.hasClass('gridAuto')) || tr.hasClass('k-grid-edit-row')) {
            return;
        }

        // edit row
        var kendoGrid = grid.data('kendoGrid');
        var model = kendoGrid.dataItem(tr);


        // condition si class 
        if (!grid.hasClass('gridUneditable')) {
            kendoGrid.editRow(tr, model);
        } else if (grid.hasClass('gridAuto')) {
            var controller = grid.attr('controller');
            var url = "/" + controller + "/Edit?";
            try {
                if (urlFiche != undefined) {
                    url = urlFiche;
                }
            } catch (exception) {
            }
            App.Net.GET(url + "id=" + model.UID);
        }

        grid.trigger("change", [tr, model]);

    });

    if (grid.hasClass('gridAuto')) {

        grid.on('click', '.delete', function (e) {
            var controller = grid.attr('controller');
            var idtype = grid.attr('idtype');
            var deleteAction = (idtype == "Guid") ? "DeleteByGuid" : "DeleteByIntId";
            var tr = $(this).parents("tr").first();
            var kendoGrid = grid.data('kendoGrid');
            var model = kendoGrid.dataItem(tr);
            DeleteGridUl('/ModalDelete/' + deleteAction, model.UID, 'Voulez-vous vraiment supprimer cet élément ?', controller, 'Suppression', 'Grid', 'Delete');
            e.preventDefault();
            e.stopPropagation();
        })
    }

    var showTooltip = function () {
        $('.tooltip').remove(); // This line removes any currently showing tootltips
        $(this).tooltip('show');
    };
    var hideTooltip = function () {
        $(this).tooltip('hide');
    };
    $("[data-toggle='tooltip']").tooltip({
        trigger: 'manual'
    }).focus(showTooltip).hover(showTooltip, hideTooltip);

    //$('[data-toggle="tooltip"]').tooltip();
    grid.trigger("dataBound");
}

function onChange(e) {
}

function initGrid(id, bouton) {

    id = id || "Grid";
    bouton = bouton || "BoutonAjout";

    /*Déplacement du bouton de la grille mis par défaut en haut au niveau de l'entete des autres boutons*/
    $("#" + id).find(".k-grid-toolbar").insertAfter($("#" + bouton));
    $(".k-toolbar").css("background", "inherit");
    $(".k-toolbar").css("border", "none");


    $(".fa-plus-circle").parents(".k-header").css("text-align", "center");
    $(".fa-plus-circle").parents(".k-header").css("padding-left", "0");
    $(".fa-plus-circle").parents(".k-header").css("padding-right", "0");
    if ($("#Grid").length !== 0) {
        var widget = $("#" + id).data("kendoGrid");
        if (widget != null && widget.element != null) {
            var height = $(window).height() - 200;
            widget.element.height(height);
            widget.resize(true);
        }
    }




}



//create a custom binder that always sets the value from the widget
kendo.data.binders.widget.nullableValue = kendo.data.Binder.extend({
    init: function (widget, bindings, options) {
        kendo.data.Binder.fn.init.call(this, widget.element[0], bindings, options);
        this.widget = widget;
        this._change = $.proxy(this.change, this);
        this.widget.bind("change", this._change);
    },
    refresh: function () {
        var value = this.bindings.nullableValue.get();
        this.widget.value(value);
    },
    change: function () {
        console.log("change");
        var value = this.widget.value();
        if (value === "") {
            value = null;
        }
        console.log(value);
        this.bindings.nullableValue.set(value);
    },
    destroy: function () {
        this.widget.unbind("change", this._change);
    }
});



function onSuccess(e) {
    if (e.type == "create" || e.type == "update") {
        //check for errors in the response
        if (e.response == null || e.response.Errors == null) {
            $('#Grid').data('kendoGrid').dataSource.read();
        }
    }
}

function onSave(e) {
    $('#' + e.sender.options.table.parent('div')[0].id).data('kendoGrid').dataSource.read();
}





var gridFilter;

