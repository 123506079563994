var Net        = require('./core/Net');
var Log        = require('./services/Log');
var Kernel        = require('./core/Kernel').default;
var bindPage = require('./bindings/bindPage');
var bind = require('./core/Bind');
// register message handlers
require('./core/MessageHandlers');

var app = {

    Kernel: new Kernel(),

    initialize: function (data, debug){
        bindPage();

        Log.debug = debug == undefined ? true : debug;
        if (data){
            Net.handleResponse(data);
        }
    },

    // Net utils

    GET:       Net.GET,
    POST:      Net.POST,
    submit:    Net.submit,

    // services
    Bind : bind,
    Net:        Net,
    Poller: require('./services/Poller'),

    // components

    Modal:      require('./components/Modal'),
    ModalFrame: require('./components/ModalFrame')

};

module.exports = app;