//var $ = require('jquery');

function Modal(modalId){
    this.id = modalId;
    this.view = $('#'+this.id);
    this.contentView = this.view;

    var self = this;
    this.view.on('click', '.js-editing.close', function(e) {
        e.preventDefault();
        self.view.modal('hide');
    });

    this.view.on('hide.bs.modal', function(e) {
        self.close();
    });

};

Modal.prototype = {

    display: function (html){
        this.contentView.html(html);
        this.view.modal({
            'backdrop': false,
            'keyboard': true
        });
        this.view.modal('show');

    },

    displayError: function (html){
        this.contentView.html("");
        var iframe = document.createElement('iframe');
        iframe.width = "100%";
        iframe.height = "800";

        this.contentView.append(iframe);
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(html);
        iframe.contentWindow.document.close();

        this.view.modal('show');
    },

    hide: function(){
      this.view.modal('hide');
    },

    /**
     * @deprecated use hide function instead
     */
    close: function (){
        if (this.onClose) {
            this.onClose();
            this.onClose = null;
        }
        this.contentView.html('');
    }

};

// singleton

var registry = {};

Modal.initialize = function (modalId){
    Modal.defaultModalId = modalId;
    if (!Modal.instance){
        Modal.instance = new Modal(modalId);
    }
};



Modal.getInstance = function (modalId){
    if (!modalId)
        modalId = Modal.defaultModalId;

    if (!registry[modalId])
        registry[modalId] = new Modal(modalId);

    return registry[modalId];

};

module.exports = Modal;
