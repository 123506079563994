export default class FacturationList {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        const valueToNotResize = ['Observations', 'Montant_HT', 'DateReglement', 'TVAFacturation'];
        const delaiEcheance = parseInt($(this.view).data("echeanceDelai") ?? "15");
        $.fn.facturationList = {

            tauxTVAChange: false,
            dateReglementChange: false,
            uploadFileChange: false,
            exportExcelFlag: false,
            newFactureAction: false,

            gridFacturation_onDataBound: function () {
                // Spécifique WebPack
                // On attends bien que Kendo ai chargé les templates pour relancer le binding des components
                WebpackApp.Kernel.bindComponents($('#Grid'));

                $.fn.facturationList.customColorPastille();
                $.fn.facturationList.customKendoCommandButton();
                $.fn.facturationList.displayButtonAction();
                $.fn.facturationList.displayButtonSupprimerAction();
                $.fn.facturationList.displayButtonActiverRelanceFactureAction();
                $.fn.facturationList.displayFacture();
                $.fn.facturationList.diplayTextLien();
                $.fn.facturationList.diplayTextLienPoseur();
                $.fn.facturationList.diplayTextLienCuisiniste();
                $.fn.facturationList.diplayTextLienPluginFacture();
                $.fn.facturationList.autoColumnWidth();
                $(".k-grid-modifier").click($.fn.facturationList.gridFacturation_UpdateMultiLignes);
                $("#FacturationWipoz").attr('style', 'display:none');
                $("#btn_UpdateAction").attr('style', 'display:none');
            },
            gridFacturation_activerRelance: function (e) {
                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
                $.ajax({
                    url: urlAction.actionActiverRelance,
                    type: "POST",
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    data: JSON.stringify({
                        projetFactureId: dataItem.Wipoz_ProjetFactureID
                    })
                })
                    .done(function (data) {
                        $(e.currentTarget).closest("tr .k-grid-activerRelance").hide();
                    });
            },
            gridFacturation_Save: function (e) {
                if ($.fn.facturationList.tauxTVAChange) {
                    e.model.Taux_TVA = parseFloat($("#Taux_TVA").val());
                    $.fn.facturationList.tauxTVAChange = false;
                    $("#btn_UpdateAction").attr('style', 'display:none');
                }

                if ($.fn.facturationList.dateReglementChange) {
                    e.model.DateReglementProxy = $("#dateReglementFacturation").val();
                    $.fn.facturationList.dateReglementChange = false;
                }

                if ($.fn.facturationList.uploadFileChange) {
                    $("#wipoz_projetFactureID").val(e.model.Wipoz_ProjetFactureID);
                    $.fn.facturationList.ajaxPostUploadFactureFile();
                    $.fn.facturationList.uploadFileChange = false;
                }
            },

            gridFacturation_UpdateChoixAction: function (e) {
                var choixAction = e.sender.selectedIndex;
                $("#choixAction").val(choixAction);

                if (choixAction === 0) {
                    $("#FacturationWipoz").attr('style', 'display:none');
                }
                else if (choixAction === 1) {
                    $("#FacturationWipoz").removeAttr('style');
                    $("#btn_UpdateAction").removeAttr('style');
                }
            },

            gridFacturation_UpdateChoixFacture: function () {
                $("#choixFacture").val(this.selectedKeyNames().join("|"));
            },

            gridFacturation_UpdateChoixValue: function (e) {
                var statusFacture = e.sender.selectedIndex;
                $("#statusFacture").val(statusFacture);

            },

            gridFacturation_UpdateMultiLignes: function (e) {
                e.stopPropagation();
                var choixfacture = $("#choixFacture").val();
                if (choixfacture !== "") {
                    swal({
                        title: Resource.UpdateMultiLigneTitle,
                        text: Resource.UpdateMultiLigneText,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: '#333333',
                        cancelButtonColor: '#D0D0D0',
                        cancelButtonText: Resource.annulerButtonText,
                        confirmButtonText: Resource.validerButtonText,
                        closeOnConfirm: false,
                        allowOutsideClick: false
                    },
                        function (isConfirm) {
                            if (isConfirm) {
                                $.ajax({
                                    url: urlAction.actionUpdateMultiLigne,
                                    type: "POST",
                                    contentType: "application/json; charset=utf-8",
                                    dataType: "json",
                                    data: JSON.stringify({
                                        choixAction: $("#choixAction").val(),
                                        choixfacture: choixfacture,
                                        statusFacture: $("#statusFacture").val()
                                    })
                                })
                                    .done(function (data) {
                                        if (data.response === 0) {
                                            $.fn.facturationList.refreshGridFacturation();
                                            toastr.success(Resource.UpdateMultiLigneSuccess);
                                        } else {
                                            toastr.error(Resource.UpdateMultiLigneFailure);
                                        }
                                    });
                            }

                            $.fn.facturationList.hideLoader();
                            swal.close();
                        }
                    );
                }
                else {
                    toastr.error(Resource.SelectionUpdateMultiLigneFailure);
                }
            },

            gridFacturation_onRequestEnd: function (e) {
                if (e.type == "update") {
                    if (e.response == null || e.response.Error == null) {
                        toastr.success(Resource.toastrSuccessUpload);
                        $.fn.facturationList.gridFacturation_onDataBound();
                        $.fn.facturationList.refreshGridFacturation();
                    } else {
                        toastr.error(Resource.toastrErrorUpload);
                    }
                }
            },

            gridFacturation_ExcelExport: function (e) {
                if (!$.fn.facturationList.exportExcelFlag) {
                    e.sender.hideColumn("PastilleEtatFacturationWipoz");
                    e.sender.hideColumn("UploadFactureFile");
                    e.sender.hideColumn("TVAFacturation");
                    e.sender.hideColumn("LienHistoDesFacturesID");


                    e.sender.showColumn("Poseur");
                    e.sender.autoFitColumn("Poseur");
                    e.sender.showColumn("Tiers");
                    e.sender.autoFitColumn("Tiers");
                    e.sender.showColumn("Contremarque");
                    e.sender.autoFitColumn("Contremarque");
                    e.sender.showColumn("CodeFacture");
                    e.sender.autoFitColumn("CodeFacture");
                    e.sender.showColumn("HistoFactureExcel");
                    e.sender.autoFitColumn("HistoFactureExcel");
                    e.sender.showColumn("CABrut");
                    e.sender.autoFitColumn("CABrut");
                    e.sender.showColumn("CANet");
                    e.sender.autoFitColumn("CANet");
                    e.sender.showColumn("Taux_TVA");
                    e.sender.autoFitColumn("Taux_TVA");

                    e.preventDefault();
                    $.fn.facturationList.exportExcelFlag = true;
                    setTimeout(function () {
                        e.sender.saveAsExcel();
                    });

                } else {
                    e.sender.hideColumn("Taux_TVA");
                    e.sender.hideColumn("CodeFacture");
                    e.sender.hideColumn("HistoFactureExcel");
                    e.sender.hideColumn("CABrut");
                    e.sender.hideColumn("CANet");
                    e.sender.hideColumn("Contremarque");
                    e.sender.hideColumn("Poseur");
                    e.sender.hideColumn("Tiers");
                    e.sender.showColumn("TVAFacturation");
                    e.sender.showColumn("UploadFactureFile");
                    e.sender.showColumn("PastilleEtatFacturationWipoz");
                    e.sender.showColumn("LienHistoDesFacturesID");
                    $.fn.facturationList.exportExcelFlag = false;
                }
            },

            successUploadFactureFile: function () {
                $.fn.facturationList.gridFacturation_onDataBound();
                $.fn.facturationList.refreshGridFacturation();
                toastr.success(Resource.toastrSuccessUpload);
            },

            failureUploadFactureFile: function () {
                $.fn.facturationList.refreshGridFacturation();
                toastr.error(Resource.toastrErrorUpload);
            },

            successDeleteFactureFile: function () {
                $.fn.facturationList.gridFacturation_onDataBound();
                $.fn.facturationList.refreshGridFacturation();
                toastr.success(Resource.toastrSuccessSupprimerFacture);
            },

            failureDeleteFactureFile: function () {
                $.fn.facturationList.refreshGridFacturation();
                toastr.error(Resource.toastrErrorSupprimerFacture);
            },

            gridFacturation_Edit: function (e) {
                $.fn.facturationList.displayFacture();
                $.fn.facturationList.diplayTextLien();
                $.fn.facturationList.diplayTextLienPluginFacture();
                $.fn.facturationList.diplayTextLienPoseur();
                $.fn.facturationList.diplayTextLienCuisiniste();
                $.fn.facturationList.customKendoEditInput();
                $.fn.facturationList.customKendoCommandButton();
                $.fn.facturationList.autoColumnWidth();
                $.fn.facturationList.diplayTextAddNewfacture(e.container[0].rowIndex);
            },

            gridFacturation_Cancel: function () {
                $.fn.facturationList.refreshGridFacturation();
                $.fn.facturationList.autoColumnWidth();
            },

            gridFacturation_supprimerFacture: function (e) {
                $.fn.facturationList.showLoader();
                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
                swal({
                    title: Resource.SupprimerFactureTitle,
                    text: Resource.SupprimerFactureText,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#333333',
                    cancelButtonColor: '#D0D0D0',
                    cancelButtonText: Resource.annulerButtonText,
                    confirmButtonText: Resource.validerButtonText,
                    closeOnConfirm: false,
                    allowOutsideClick: false
                },
                    function (isConfirm) {
                        if (isConfirm) {
                            if (dataItem.FactureCuisinisteID != null) {
                                $.ajax({
                                    url: urlActionInPluginFacture.actionDeleteFacture,
                                    type: "POST",
                                    contentType: "application/json; charset=utf-8",
                                    dataType: "json",
                                    data: JSON.stringify({ id: dataItem.FactureCuisinisteID })
                                })
                                    .done(function (data, status, jqXHR) {
                                        if (status === 'success') {
                                            $.fn.facturationList.ajaxPostSupprimerFacturation(dataItem);
                                        } else {
                                            $.fn.facturationList.hideLoader();
                                            $.fn.facturationList.failureDeleteFactureFile();
                                        }
                                    }
                                    );
                            }
                            else {
                                $.fn.facturationList.ajaxPostSupprimerFacturation(dataItem);
                            }
                        }

                        $.fn.facturationList.hideLoader();
                        swal.close();
                    });
            },

            ajaxPostSupprimerFacturation: function (e) {
                $.ajax({
                    url: urlAction.actionSupprimerFacturation,
                    type: "POST",
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    data: JSON.stringify({ facturationItem: e })
                }).done(function (data, status, jqXHR) {
                    if (status === 'success') {
                        $.fn.facturationList.successDeleteFactureFile();
                    } else {
                        $.fn.facturationList.hideLoader();
                        $.fn.facturationList.failureDeleteFactureFile();
                    }
                }
                );
            },
            gridFacturation_validationFacture: function (e) {
                $.fn.facturationList.showLoader();
                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
                $.fn.facturationList.newFactureAction = false;
                if (dataItem.IsTestOrDemo && $("#IsProd").val() === "True") {
                    swal({
                        title: Resource.validerFactureTitle,
                        text: Resource.validerFactureTextBis,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: '#333333',
                        cancelButtonColor: '#D0D0D0',
                        cancelButtonText: Resource.annulerButtonText,
                        confirmButtonText: Resource.validerButtonText,
                        closeOnConfirm: false,
                        allowOutsideClick: false
                    },
                        function () {
                            swal.close();
                        }
                    );
                }
                else {
                    swal({
                        title: Resource.validerFactureTitle,
                        text: Resource.validerFactureText,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: '#333333',
                        cancelButtonColor: '#D0D0D0',
                        cancelButtonText: Resource.annulerButtonText,
                        confirmButtonText: Resource.validerButtonText,
                        closeOnConfirm: false,
                        allowOutsideClick: false
                    },
                        function (isConfirm) {
                            if (isConfirm) {
                                $.fn.facturationList.ajaxPostValiderFacturation(dataItem);
                            }
                            else {
                                $.fn.facturationList.hideLoader();
                            }
                            swal.close();
                        }
                    );
                }
            },
            ajaxPostValiderFacturation: function (e) {
                $.ajax({
                    url: urlAction.actionValiderFacturation,
                    type: "POST",
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    data: JSON.stringify({ facturationItem: e })
                }).done(function (data, status) {
                    if (status === 'success') {
                        toastr.success(Resource.toastrSuccessValiderFacture);
                        $.fn.facturationList.ajaxPostCreationFacture(data.Data[0]);
                    } else {
                        $.fn.facturationList.hideLoader();
                        toastr.error(Resource.toastrErrorValiderFacture);
                    }
                });
            },
            ajaxPostCreationFacture: function (e) {
                var today = new Date();
                var echeance = new Date();
                echeance.setDate(today.getDate() + delaiEcheance);
                $.ajax({
                    url: urlActionInPluginFacture.actionCreationFacture,
                    type: "POST",
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    data: JSON.stringify({
                        DateEmission: today,
                        Type: 0, //type = Facture
                        EntrepriseId: e.CuisinisteID,
                        ClientId: e.CuisinisteID,
                        LibelleCommande: e.Contremarque,
                        ReferenceCommande: e.RefWipoz,
                        DateEcheance: echeance,
                        ConditionReglementID: e.FactureConditionReglementID,
                        ExterneID: e.Wipoz_ProjetID,
                        Articles: e.Articles.map(article => { return { designation: article.ArtDesignation, articleCode: article.ArtReference, prix: article.Montant, quantite: 1 } })
                    })
                }).done(function (data, status, jqXHR) {
                    if (status === 'success') {
                        $.fn.facturationList.sweetAlertSuccess_CreationFacture(data.id);
                    } else {
                        $.fn.facturationList.hideLoader();
                        toastr.error(Resource.toastrErrorCreationFactureCuisiniste);
                    }
                }
                );
            },
            sweetAlertSuccess_CreationFacture: function (id) {
                swal({
                    title: Resource.toastrSuccessCreationFactureCuisiniste,
                    type: "success",
                    confirmButtonColor: '#333333',
                    confirmButtonText: Resource.validerButtonText,
                    allowOutsideClick: false
                },
                    function (isConfirm) {
                        if (isConfirm) {
                            window.open(urlActionInPluginFacture.actionEditFacture + "/" + id, '_blank');
                        }
                        $.fn.facturationList.hideLoader();
                        swal.close();

                        $.fn.facturationList.refreshGridFacturation();
                    }
                );
            },

            ajaxPostUploadFactureFile: function () {
                $.ajax({
                    url: urlAction.actionUploadFactureFile,
                    type: "POST",
                    processData: false,
                    contentType: false,
                    dataType: "json",
                    data: new FormData($("#form-upload-facture").get(0))
                })
                    .done(function (data) {
                        if (data.response === 0) {
                            $.fn.facturationList.successUploadFactureFile();
                        } else {
                            $.fn.facturationList.failureUploadFactureFile();
                        }
                    }
                    );
            },

            gridFacturation_lienProjet: function (e) {
                $.fn.facturationList.showLoader();
                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
                var urlEditProjet = urlAction.actionEditProjet;
                if (dataItem.Wipoz_ProjetID !== null) {
                    urlEditProjet += "/" + dataItem.Wipoz_ProjetID;
                }
                swal({
                    title: Resource.lienProjetTitle,
                    text: Resource.lienProjetText,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#333333',
                    cancelButtonColor: '#D0D0D0',
                    cancelButtonText: Resource.annulerButtonText,
                    confirmButtonText: Resource.validerButtonText,
                    closeOnConfirm: false,
                    allowOutsideClick: false
                },
                    function (isConfirm) {
                        if (isConfirm) {
                            window.open(urlEditProjet, '_blank');
                        }
                        $.fn.facturationList.hideLoader();
                        swal.close();
                    }
                );
            },

            gridFacturation_lienCuisiniste: function (e) {
                $.fn.facturationList.showLoader();
                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
                var urlEditClient = urlAction.actionEditClient;
                if (dataItem.CuisinisteID !== null) {
                    urlEditClient += "/" + dataItem.CuisinisteID;
                }
                swal({
                    title: Resource.lienCuisinisteTitle,
                    text: Resource.lienCuisinisteText,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#333333',
                    cancelButtonColor: '#D0D0D0',
                    cancelButtonText: Resource.annulerButtonText,
                    confirmButtonText: Resource.validerButtonText,
                    closeOnConfirm: false,
                    allowOutsideClick: false
                },
                    function (isConfirm) {
                        if (isConfirm) {
                            window.open(urlEditClient, '_blank');
                        }
                        $.fn.facturationList.hideLoader();
                        swal.close();
                    }
                );
            },

            gridFacturation_lienPoseur: function (e) {
                $.fn.facturationList.showLoader();
                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
                var urlEditClient = urlAction.actionEditClient;
                if (dataItem.Wipoz_ClientID !== null) {
                    urlEditClient += "/" + dataItem.Wipoz_ClientID;
                }
                swal({
                    title: Resource.lienPoseurTitle,
                    text: Resource.lienPoseurText,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#333333',
                    cancelButtonColor: '#D0D0D0',
                    cancelButtonText: Resource.annulerButtonText,
                    confirmButtonText: Resource.validerButtonText,
                    closeOnConfirm: false,
                    allowOutsideClick: false
                },
                    function (isConfirm) {
                        if (isConfirm) {
                            window.open(urlEditClient, '_blank');
                        }
                        $.fn.facturationList.hideLoader();
                        swal.close();
                    }
                );
            },

            gridFacturation_lienPluginFacture: function (e) {
                $.fn.facturationList.showLoader();
                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
                var urlEditFacture = urlActionInPluginFacture.actionEditFacture;
                if (dataItem.LienFactureCuisinisteID !== "") {
                    urlEditFacture += "/" + dataItem.LienFactureCuisinisteID;
                }
                swal({
                    title: Resource.lienPluginFactureTitle,
                    text: Resource.lienPluginFactureText,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#333333',
                    cancelButtonColor: '#D0D0D0',
                    cancelButtonText: Resource.annulerButtonText,
                    confirmButtonText: Resource.validerButtonText,
                    closeOnConfirm: false,
                    allowOutsideClick: false
                },
                    function (isConfirm) {
                        if (isConfirm) {
                            window.open(urlEditFacture, '_blank');
                        }
                        $.fn.facturationList.hideLoader();
                        swal.close();
                    }
                );
            },

            gridFacturation_PluginFactAddNewFactureCuisiniste: function (e) {
                $.fn.facturationList.showLoader();
                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));

                swal({
                    title: Resource.AddNewFactureCuisinisteTitle,
                    text: Resource.AddNewFactureCuisinisteText,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#333333',
                    cancelButtonColor: '#D0D0D0',
                    cancelButtonText: Resource.annulerButtonText,
                    confirmButtonText: Resource.validerButtonText,
                    closeOnConfirm: false,
                    allowOutsideClick: false
                },
                    function (isConfirm) {
                        if (isConfirm) {
                            newFactureAction = true;

                            $.fn.facturationList.ajaxPostUpdateFacturation(dataItem);

                            $.fn.facturationList.refreshGridFacturation();
                        }
                        else {
                            $.fn.facturationList.hideLoader();
                        }
                        swal.close();
                    }
                );
            },

            ajaxPostUpdateFacturation: function (e) {
                $.ajax({
                    url: urlAction.actionUpdateFacturation,
                    type: "POST",
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    data: JSON.stringify({ facturationItem: e })
                })
                    .done(function (data, status) {
                        if (status === 'success') {
                            $.ajax({
                                url: urlAction.actionAddNewFactureCuisiniste,
                                type: "POST",
                                contentType: "application/json; charset=utf-8",
                                dataType: "json",
                                data: JSON.stringify({ facturationItem: e })
                            })
                                .done(function (data, status) {
                                    if (status === 'success') {
                                        toastr.success(Resource.toastrSuccessAddNewFactureCuisiniste);
                                        $.fn.facturationList.ajaxPostGetEntreprisesFacturables(data.Data[0]);
                                    } else {
                                        $.fn.facturationList.hideLoader();
                                        toastr.error(Resource.toastrErrorAddNewFactureCuisiniste);
                                    }
                                }
                                );
                        } else {
                            $.fn.facturationList.hideLoader();
                            toastr.error(Resource.toastrErrorAddNewFactureCuisiniste);
                        }
                    }
                    );
            },

            refreshGridFacturation: function () {
                $('#Grid').data('kendoGrid').dataSource.read();
                $('#Grid').data('kendoGrid').refresh();
            },


            autoColumnWidth: function () {
                var grid = $('#Grid').data('kendoGrid');
                for (var i = 0; i < grid.columns.length; i++) {
                    if (i > 1 && !valueToNotResize.includes(grid.columns[i].field)) {
                        grid.autoFitColumn(i);
                    }
                }
            },

            displayButtonAction: function () {
                var allLineActionFacture = $(".line-edit-btn");
                allLineActionFacture.each(function (i) {
                    var isFactureValidee = $(".isFactureValidee")[i].innerText;
                    if (isFactureValidee === 'true') {
                        $($(".k-grid-validerFacture")[i]).hide();
                        $($(".k-grid-supprimerFacture")[i]).hide();
                    } else {
                        $($(".k-grid-validerFacture")[i]).show();
                        $($(".k-grid-supprimerFacture")[i]).show();
                    }
                });
            },

            displayButtonSupprimerAction: function () {
                var allLineActionFacture = $(".line-edit-btn");
                allLineActionFacture.each(function (i) {
                    var isFactureValidee = $(".StatutPluginfacturation")[i].innerText;
                    if (isFactureValidee === "" || isFactureValidee === "0") {
                        $($(".k-grid-supprimerFacture")[i]).show();
                    } else {
                        $($(".k-grid-supprimerFacture")[i]).hide();
                    }
                });
            },
            displayButtonActiverRelanceFactureAction: function () {
                var allLineActionFacture = $(".line-edit-btn");
                allLineActionFacture.each(function (i) {
                    var value = $(".isRappelFacture")[i].innerText;
                    if (value === "" || value === "false") {
                        $($(".k-grid-activerRelance")[i]).hide();
                    } else {
                        $($(".k-grid-activerRelance")[i]).show();
                    }
                });
            },

            displayFacture: function () {
                var allLienFacturePoseur = $(".lienFacturePoseur");
                allLienFacturePoseur.each(function (i) {
                    var pathname = $(".lienFacturePoseur")[i].pathname;
                    if (pathname !== undefined && pathname !== "" && !pathname.includes("null")) {
                        $($(".lienFacturePoseur")[i]).addClass("active");
                    }
                    else {
                        $($(".lienFacturePoseur")[i]).hide();
                    }
                });
                var allLienFactureCuisiniste = $(".lienPluginFacture");
                allLienFactureCuisiniste.each(function (i) {
                    var factureCuisinisteID = $(".lienFactureCuisiniste")[i].innerText;
                    if (factureCuisinisteID === undefined || factureCuisinisteID === "" || factureCuisinisteID.includes("null")) {
                        $($(".k-grid-lienPluginFacture")[i]).hide();
                    }
                });
            },

            diplayTextLienPluginFacture: function () {
                $(".k-grid-lienPluginFacture").each(function (i) {
                    $($(".k-grid-lienPluginFacture")[i]).text($(".codeFacture")[i].textContent);
                });
            },

            diplayTextLien: function () {
                $(".k-grid-lienProjet").each(function (i) {
                    $($(".k-grid-lienProjet")[i]).text($(".Contremarque")[i].textContent);
                });
            },

            diplayTextLienCuisiniste: function () {
                $(".k-grid-lienCuisiniste").each(function (i) {
                    $($(".k-grid-lienCuisiniste")[i]).text($(".Cuisiniste")[i].textContent);
                });

                $(".k-grid-lienPluginFactureAdd").each(function (i) {
                    $($(".k-grid-lienPluginFactureAdd")[i]).attr('style', 'display:none');
                });
            },

            diplayTextAddNewfacture: function (index) {
                if ($(".codeFacture")[index].textContent !== "") {
                    $($(".k-grid-lienPluginFactureAdd")[index]).removeAttr('style', 'display:none');
                }
            },

            diplayTextLienPoseur: function () {
                $(".k-grid-lienPoseur").each(function (i) {
                    $($(".k-grid-lienPoseur")[i]).text($(".Poseur")[i].textContent);
                });
            },

            onEtatFacturationWipozDatabound: function () { },

            onUploadFileChange: function (e) {
                var error = e.files[0].validationErrors;
                if (error !== undefined && error.length > 0) {
                    toastr.error(Resource.extensionFileError);
                }
                $.fn.facturationList.uploadFileChange = true;
            },

            onRemoveFile: function () {
                $.fn.facturationList.uploadFileChange = false;
            },

            onTVAChange: function (e) {
                $.fn.facturationList.tauxTVAChange = true;
                var dropdownlist = e.sender.element.data("kendoDropDownList");
                $("#Taux_TVA").val(dropdownlist.text());
            },

            onDatePickerChange: function () { $.fn.facturationList.dateReglementChange = true; },

            customKendoCommandButton: function () {
                $.fn.facturationList.customActionCommandButton($(".k-grid-edit"), Resource.editCommandTitle, true);
                $.fn.facturationList.customActionCommandButton($(".k-grid-update"), Resource.updateCommandTitle, true);
                $.fn.facturationList.customActionCommandButton($(".k-grid-cancel"), Resource.cancelCommandTitle, true);
                $.fn.facturationList.customActionCommandButton($(".k-grid-validerFacture"), Resource.validerFactureTitle, true);
                $.fn.facturationList.customActionCommandButton($(".k-grid-activerRelance"), Resource.ActiverRelanceFacture, true);
                $.fn.facturationList.customActionCommandButton($(".k-grid-supprimerFacture"), Resource.SupprimerFactureTitle, true);
                $.fn.facturationList.customActionCommandButton($(".k-grid-lienProjet"), Resource.lienProjetTitle, false);
                $.fn.facturationList.customActionCommandButton($(".k-grid-lienCuisiniste"), Resource.lienCuisinisteTitle, false);
                $.fn.facturationList.customActionCommandButton($(".k-grid-lienPoseur"), Resource.lienPoseurTitle, false);
                $.fn.facturationList.customActionCommandButton($(".k-grid-lienPluginFacture"), Resource.lienPluginFactureTitle, false);
                $.fn.facturationList.customActionCommandButton($(".iconeAction"), Resource.iconeActionTitle, true);
            },

            customActionCommandButton: function (selector, titleBtn, textHaveToBeHidden) {
                selector.each(function (i) {
                    var span = $(selector[i]).children();
                    if (textHaveToBeHidden) {
                        $(selector[i]).text("");
                    }
                    $(selector[i]).append(span);
                    $(selector[i]).attr("title", titleBtn);
                });
            },

            customKendoEditInput: function () {
                var kendoInput = $("input.text-box");
                kendoInput.each(function (index) {
                    $(kendoInput[index]).addClass("form-control");
                });
            },

            customColorPastille: function () {
                $(".pastille").each(function (i) {
                    var textEtatFacturationWipoz = $($(".etatFacturationWipoz")[i]).text();
                    var colorPastille = $($(".colorPastille")[i]);
                    switch (textEtatFacturationWipoz) {
                        case etatFacturationWipoz.etatWipozEnCours:
                            colorPastille.toggleClass("redPastille");
                            break;
                        case etatFacturationWipoz.etatWipozEnAttente:
                            colorPastille.toggleClass("orangePastille");
                            break;
                        case etatFacturationWipoz.etatWipozReglee:
                            colorPastille.toggleClass("greenPastille");
                            break;
                    }
                });
            },

            showLoader: function () {
                $("#GlobalLoader").show();
            },

            hideLoader: function () {
                $("#GlobalLoader").hide();
            },

            addfilter: function () {
                return {
                    RechercheGlobale: $('input[name="RechercheGlobale"]').val(),
                    MasquerProjetTests: $('input[name="MasquerProjetTests"]').is(':checked'),
                    RechercheEtatFacturationCuisiniste: $("#RechercheEtatFacturationCuisiniste").val(),
                    RechercheEtatFacturationWipoz: $("#RechercheEtatFacturationWipoz").val(),
                    FacturationAValider: $('input[name="FacturationAValider"]').is(':checked'),
                    FacturationValidee: $('input[name="FacturationValidee"]').is(':checked'),
                    MarketId: $("#MarketId").val()
                }
            },
            onMarketChanged: function () {
                $.fn.facturationList.refreshGridFacturation();
                var marketId = $("#MarketId").val();
                $.ajax({
                    url: urlAction.actionRefreshTiles + '?marketId=' + marketId,
                    method: "GET",
                    data: {},
                    success: function (data) {
                        $(".tiles-grid").replaceWith(data);
                    },
                    error: function () {
                    }
                });
            }
        };
    }
}
