var Net = require('./Net');

/*
bindings API
*/
function bindTooltips(target) {

    var ttconf = {
        container: 'body',
        html: true
    };

    target.find('[rel="tooltip"]').tooltip(ttconf);
    target.find('[data-toggle="tooltip"]').tooltip(ttconf);
}

function bindXHR(target) {
    target.on('click', 'a[data-src="xhr"]', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var url = $(this).attr('href');
        var confirm = $(this).attr('data-confirm');
        if (confirm) {
            var confirmButtonText = $(this).attr('data-confirm-confirmtext');
            if (!confirmButtonText) {
                confirmButtonText = 'Oui';
            }
            var cancelButtonText = $(this).attr('data-confirm-canceltext');
            if (!cancelButtonText) {
                cancelButtonText = 'Non';
            }
            swal({
                title: confirm,
                text: "",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: confirmButtonText,
                cancelButtonText: cancelButtonText,
                closeOnConfirm: false
            }, function () {
                Net.GET(url);
                swal.close();
            });
        } else {
            Net.GET(url);
        }
    });
}

function bindForm(target) {
    target.on('submit', 'form[data-src="xhr"]', function (e) {
        e.preventDefault();
        Net.submit($(this));
    });
}

module.exports = {
    Page: function () {
        var target = $('body');
        bindXHR(target);
        bindForm(target);
        bindTooltips(target);
    },

    Partial: function (target) {
        bindTooltips(target);
    }
};