var Net = require('../core/Net');

function _hash(key){
    var hash = 0, i, chr, len;
    if (key.length === 0)
        return hash;

    for (i = 0, len = key.length; i < len; i++) {
        chr   = key.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }

    return hash;
}

var api = {

    _timers: {},

    start: function (url, interval){
        api.stop(url);
        var k = _hash(url);
        api._timers[k] = window.setInterval(function (){
            Net.GET(url, null, true);
        }, interval*1000);
    },

    stop: function (url){
        var k = _hash(url);
        var timer = api._timers[k];
        if (timer){
            window.clearInterval(timer);
        }
        api._timers[k] = null;
    }

};

module.exports = api;
