export default class Kernel{

    constructor(){
        this.factories = [];
    }

    registerComponent(uname, factory){

        let wrapper = factory;

        if (isNativeClass(factory)){
            wrapper = function ($html){
                return new factory($html);
            };
        }

        this.factories[uname] = wrapper;
        return this;
    }

    createComponent($html){

        var uname = $html.data('mount');

        if (!uname)
            return null;

        var factory = this.factories[uname];
        if (!factory)
            return null;

        return factory($html);
    }

    bindComponents($view){
        var self = this;
        $view.find('[data-mount]').each(function (idx, node){

            var $node = $(node);

            if ($node.data('component'))
                return null;

            var component = self.createComponent($node);
            $node.data('component', component);

        });
    }

}

function isNativeClass (thing) {
    return typeof thing === 'function' && thing.hasOwnProperty('prototype');
}
