import $ from 'jquery';
window.jQuery = $;
window.$ = $;

require('./scss/main.scss');

require('bootstrap');
var JSZip = require("jszip");
window.JSZip = JSZip;
import '@progress/kendo-ui/js/kendo.dropdownlist';
import '@progress/kendo-ui/js/kendo.combobox';
import '@progress/kendo-ui/js/kendo.upload';
import '@progress/kendo-ui/js/kendo.tabstrip';
import '@progress/kendo-ui/js/kendo.aspnetmvc';
import '@progress/kendo-ui/js/kendo.multiselect';
import '@progress/kendo-ui/js/kendo.datepicker';
import '@progress/kendo-ui/js/kendo.numerictextbox';
import '@progress/kendo-ui/js/kendo.grid';
import '@progress/kendo-ui/js/kendo.scheduler';
import '@progress/kendo-ui/js/kendo.dataviz.map';
import '@progress/kendo-ui/js/kendo.autocomplete';
import '@progress/kendo-ui/js/kendo.panelbar';
import '@progress/kendo-ui/js/kendo.editor';

// PYT 06/06/2023 
// J'ai essayé d'importer les cultures et messages de chaque langue
// Mais cela se semblait prendre en compte pour kendo que la dernière culture importée 
// Et je n'arrive pas a importer uniquement la culture voulu (workaround _Culture.cshtml)

//import '@progress/kendo-ui/js/cultures/kendo.culture.fr-FR';
//import '@progress/kendo-ui/js/messages/kendo.messages.fr-FR';

//import '@progress/kendo-ui/js/cultures/kendo.culture.fr-BE';
//import '@progress/kendo-ui/js/messages/kendo.messages.fr-BE';

//import '@progress/kendo-ui/js/cultures/kendo.culture.nl-BE';
//import '@progress/kendo-ui/js/messages/kendo.messages.nl-BE';

//import '@progress/kendo-ui/js/cultures/kendo.culture.en-GB';
//import '@progress/kendo-ui/js/messages/kendo.messages.en-GB';

//import '@progress/kendo-ui/js/cultures/kendo.culture.nl-NL';
//import '@progress/kendo-ui/js/messages/kendo.messages.nl-NL';

require('jquery-validation');
require('jquery-ajax-unobtrusive');
require('jquery-validation-unobtrusive');
require('sweetalert');
require('jquery-ui/dist/jquery-ui.min.js');
var Globalize = require('globalize/lib/globalize.js');
require('globalize/lib/cultures/globalize.culture.fr-FR.js');
require('globalize/lib/cultures/globalize.culture.en-GB.js');
require('globalize/lib/cultures/globalize.culture.fr-BE.js');
require('globalize/lib/cultures/globalize.culture.nl-NL.js');
window.Globalize = Globalize;

import 'cropper';

import 'admin/extension/Grid';
import { UploadPhoto, closeUploadPhoto, SupprimerPhoto } from 'admin/extension/photoCropper.js';
window.UploadPhoto = UploadPhoto;
window.SupprimerPhoto = SupprimerPhoto;
window.closeUploadPhoto = closeUploadPhoto;
import 'admin/extension/Client';
import 'admin/extension/Projet';
import 'admin/extension/kpi';
require('admin/extension/Global.js');

import moment from 'moment';
window.moment = moment;

import * as toastr from 'toastr';
window.toastr = toastr;

import 'admin/extension/search';

require('admin/extension/6tm-grid');
require('admin/extension/Grid');

import App from 'admin/6tm-webpack/app';
import Swal from 'admin/Swal/index';
import ModalAjax from 'admin/ModalAjax/index';
import DeleteClientDocument from 'admin/DeleteClientDocument';
import FacturationList from 'admin/FacturationList';
import AddressAutoComplete from 'admin/AddressAutoComplete';
import MultiSelect from 'admin/MultiSelect';


window._ = require('lodash');

App.Kernel
    .registerComponent('Swal', Swal)
    .registerComponent('ModalAjax', ModalAjax)
    .registerComponent('FacturationList', FacturationList)
    .registerComponent('DeleteClientDocument', DeleteClientDocument)
    .registerComponent('AddressAutoComplete', AddressAutoComplete)
    .registerComponent('MultiSelect', MultiSelect);

window.WebpackApp = App;
window.App = App;

$(function () {

    WebpackApp.Kernel.bindComponents($('html'));
})

