$.fn.Client = {
    lock: function (e, id) {
        e.preventDefault();
        $.ajax({
            url: Resource.lockUrlGetModal,
            type: 'POST',
            data: { id: id },
            success: function (data) {
                $("#mModal .modal-dialog").html(data);
                $("#mModal").modal("show");
            }
        });
    },
    lockOnSuccess: function () {
        $("#mModal").modal("hide");
        $("#mModal .modal-dialog").html("");
        toastr.success(Resource.lockSuccess);
        setTimeout(function () { window.location.reload(true); }, 2000);
    },
    cancelLock: function () {
        $("#mModal").modal("hide");
        $("#mModal .modal-dialog").html("");
        return false;
    },
    unlock: function (e, id) {
        e.preventDefault();
        swal({
            title: Resource.unlockTitle,
            text: Resource.unlockMessage,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#333333',
            cancelButtonColor: '#D0D0D0',
            cancelButtonText: Resource.No,
            confirmButtonText: Resource.Yes,
            closeOnConfirm: false,
            allowOutsideClick: false
        },
            function (isConfirm) {
                if (isConfirm) {
                    $.ajax({
                        url: Resource.unlockUrl,
                        type: "POST",
                        contentType: "application/json; charset=utf-8",
                        dataType: "json",
                        data: JSON.stringify({
                            id: id
                        })
                    }).done(function (data) {
                        if (data.success === 1) {
                            toastr.success(Resource.unlockSuccess);
                            setTimeout(function () { window.location.reload(true); }, 2000);
                        }
                        else {
                            toastr.error(Resource.unlockFailed);
                        }
                    });
                }
                swal.close();
            }
        );
    },
    deleteLogo: function () {
        swal({
            title: Resource.deleteLogoTitle,
            text: Resource.deleteLogoMessage,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#333333',
            cancelButtonColor: '#D0D0D0',
            cancelButtonText: Resource.No,
            confirmButtonText: Resource.Yes,
            closeOnConfirm: false,
            allowOutsideClick: false
        },
            function (isConfirm) {
                if (isConfirm) {
                    $.ajax({
                        url: Resource.DeleteLogo,
                        type: "POST",
                        contentType: "application/json; charset=utf-8",
                        dataType: "json",
                    }).done(function (data) {
                        if (data.success === 1) {
                            toastr.success(Resource.deleteLogoSuccess);
                            $.fn.Client.reloadLogo();
                        }
                        else {
                            toastr.error(Resource.deleteLogoFailed);
                        }
                    });
                }
                swal.close();
            }
        );
    },
    errorLogo: function (e) {
        e.style.display = 'none';
        $("#logoDelete").hide();
    },
    reloadLogo: function () {
        $("#logoDelete").show();
        var d = new Date();
        var masource = $("#imageLogo").attr("src");
        if (masource) {
            if (masource.indexOf("?") > 0) {
                masource += "&";
            }
            else {
                masource += "?";
            }
        }
        else {
            masource = "?";
        }
        $("#imageLogo").attr("src", masource + d.getTime());
    }
};