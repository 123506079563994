'use strict';

if (!String.prototype.format) {
    String.prototype.format = function () {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
                ;
        });
    };
}

/*
event binders
*/
function bindMenu() {
    $('.toggle-menu').click(function (e) {
        e.preventDefault();
        $('#body').toggleClass('menu-closed');

        if ($('#body').hasClass('menu-closed')) {
            document.cookie = "menuState=close; path=/";
            $(".userSessionFullName").css("display", "none");
            $(".userSessionShortName").css("display", "block");
            $("#menu-logo").css("display", "none");
            $("#menu-icon").css("display", "block");

        } else {
            document.cookie = "menuState=open; path=/";
            $(".userSessionFullName").css("display", "block");
            $(".userSessionShortName").css("display", "none");
            $("#menu-logo").css("display", "block");
            $("#menu-icon").css("display", "none");
        }

    });
}


/* errors grid kendo */
//show server errors if any
function error_handler(e) {
    if (e.errors) {
        var message = "Errors:\n";
        $.each(e.errors, function (key, value) {
            if ('errors' in value) {
                $.each(value.errors, function () {
                    message += this + "\n";
                });
            }
        });
        alert(message);
    }
}


/* Champs obligatoires */

(function ($, Globalize) {

    // Clone original methods we want to call into
    var originalMethods = {
        min: $.validator.methods.min,
        max: $.validator.methods.max,
        range: $.validator.methods.range
    };

    // Tell the validator that we want numbers parsed using Globalize

    $.validator.methods.number = function (value, element) {
        var val = Globalize.parseFloat(value);
        return this.optional(element) || ($.isNumeric(val));
    };

    // Tell the validator that we want dates parsed using Globalize

    $.validator.methods.date = function (value, element) {
        var val = Globalize.parseDate(value);
        return this.optional(element) || (val);
    };

    // Tell the validator that we want numbers parsed using Globalize, 
    // then call into original implementation with parsed value

    $.validator.methods.min = function (value, element, param) {
        var val = Globalize.parseFloat(value);
        return originalMethods.min.call(this, val, element, param);
    };

    $.validator.methods.max = function (value, element, param) {
        var val = Globalize.parseFloat(value);
        return originalMethods.max.call(this, val, element, param);
    };

    $.validator.methods.range = function (value, element, param) {
        var val = Globalize.parseFloat(value);
        return originalMethods.range.call(this, val, element, param);
    };

}(jQuery, Globalize));

$(document).ready(function () {
    jsRefresh();
});

jQuery(function ($) {
    var methods = ['addClass'];

    $.each(methods, function (index, method) {
        var originalMethod = $.fn[method];

        $.fn[method] = function () {
            var oldClass = typeof this[0] != 'undefined' ? this[0].className : '';
            var result = originalMethod.apply(this, arguments);
            var newClass = typeof this[0] != 'undefined' ? this[0].className : '';

            this.trigger(method, [oldClass, newClass]);

            return result;
        };
    });
});

jQuery(function ($) {

    var oldShow = $.fn.show;
    $.fn.show = function (speed, oldCallback) {
        return $(this).each(function () {
            var obj = $(this),
                newCallback = function () {
                    if ($.isFunction(oldCallback)) {
                        oldCallback.apply(obj);
                    }
                    obj.trigger('afterShow');
                };

            obj.trigger('beforeShow');

            oldShow.apply(obj, [speed, newCallback]);
        });
    }
});

var crmAlert = {
    success: function (message, timer) {
        var swalParam = { title: "OK", text: message, type: "success" };
        if (typeof timer !== 'undefined') {
            swalParam.timer = timer;
        }
        swal(swalParam);
    },
    error: function (message) {
        swal({ title: "Erreur", text: message, type: "error", confirmButtonColor: "#DD6B55", confirmButtonText: "OK", closeOnConfirm: true });
    },
    confirm: function (title, message, callback) {
        return swal({ title: title, text: message, type: "warning", showCancelButton: true, confirmButtonColor: "#DD6B55", confirmButtonText: "Oui", cancelButtonText: "Non", closeOnConfirm: true, closeOnCancel: true },
            function (isConfirm) {
                if (isConfirm) {
                    callback();
                }
            });
    },
    info: function (message) {
        swal({ title: "Attention", text: message, type: "info", confirmButtonColor: "#DD6B55", confirmButtonText: "OK", closeOnConfirm: true });
    }
}

function jsRefresh() {
    $.validator.unobtrusive.parse('form');
}

window.bindMenu = bindMenu;
window.error_handler = error_handler;
window.crmAlert = crmAlert;
