export default class MultiSelect {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        var selectUrl = $(this.view).data('urlAdd');
        var deselectUrl = $(this.view).data('urlRemove');
        var id = $(this.view).data('id');
        var addErrorMessage = $(this.view).data('errorAdd');
        var removeErrorMessage = $(this.view).data('errorRemove');
        var control = $(this.view).data("kendoMultiSelect");
        control.bind("select", function (e) {
            $.ajax({
                url: selectUrl,
                method: "POST",
                data: { id: id, value: e.dataItem.Value },
                success: function (data) {
                    if (data.success == 1) {
                        toastr.success(data.message);
                    }
                    else {
                        toastr.error(data.message);
                    }
                },
                error: function () {
                    toastr.error(addErrorMessage);
                }
            });
        });
        control.bind("deselect", function (e) {
            $.ajax({
                url: deselectUrl,
                method: "POST",
                data: { id: id, value: e.dataItem.Value },
                success: function (data) {
                    if (data.success == 1) {
                        toastr.success(data.message);
                    }
                    else {
                        toastr.error(data.message);
                    }
                },
                error: function () {
                    toastr.error(removeErrorMessage);
                }
            });
        });
    }
}
